exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-restaurant-tsx": () => import("./../../../src/pages/our-restaurant.tsx" /* webpackChunkName: "component---src-pages-our-restaurant-tsx" */),
  "component---src-templates-explore-tsx": () => import("./../../../src/templates/Explore.tsx" /* webpackChunkName: "component---src-templates-explore-tsx" */),
  "component---src-templates-policy-tsx": () => import("./../../../src/templates/Policy.tsx" /* webpackChunkName: "component---src-templates-policy-tsx" */),
  "component---src-templates-room-tsx": () => import("./../../../src/templates/Room.tsx" /* webpackChunkName: "component---src-templates-room-tsx" */)
}

